
.StripeElement.StripeElement--empty,
.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--complete{
  display: block;
  width: 100%;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--falcon-input-color);
  background-color: var(--falcon-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--falcon-input-border-color);
  appearance: none;
  border-radius: 5px;
}

.select-custom{
  // background: #EEF0F2 !important;
  border: 1px solid #7D899B !important;
  border-radius: 30px !important;
  padding: 0px 8px 0px 8px !important;
}

.custom-btn #custom-dropdown{
  background: rgba(51, 116, 255, 0.2);
  border: 1px solid #3374FF;
  border-radius: 30px;
  color: #3374FF;
  padding: 0px 8px 0px 8px;
}
.custom-btn #custom-dropdown:hover{
  background: #3374FF;
  color: white;
}

.custom-border{
  background: #FFFFFF;
    border: 1px solid #D8E2EE;
    border-radius: 6px;
    padding: 15px;
}

.questionnaire-form .form-label{
  font-size: 13px;
}

.lead-send-btn button{
  padding: 2px 8px 2px 8px;
}

.text-ellipsis{
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-to p{
  margin-bottom: 0px !important;
}

.div-disabled{
  pointer-events: none; /* Disable all click, hover, and keyboard events */
  opacity: 0.6;        /* Make it look faded to indicate it's disabled */
  user-select: none;   /* Prevent text selection */
}